<template>
  <video
    v-bind="$attrs"
    ref="videoRefDark"
    class="hidden dark:block"
    muted
    autoplay
    :loop="loop || false"
    :src="$isSafari ? undefined : darkSrc || undefined"
    :poster="posterDark ? img(posterDark) : undefined"
  ></video>
  <video
    v-bind="$attrs"
    ref="videoRefLight"
    class="dark:hidden"
    muted
    autoplay
    :loop="loop || false"
    :src="$isSafari ? undefined : lightSrc || undefined"
    :poster="posterLight ? img(posterLight) : undefined"
  ></video>
</template>
<script setup lang="ts">
defineOptions({
  inheritAttrs: false,
})

const props = defineProps<{
  light: string
  dark: string
  posterLight?: string
  posterDark?: string
  loop?: boolean
}>()

const theme = useColorMode()
const { $isSafari } = useNuxtApp()

const playPromise = ref<Promise<void> | null>(null)
const darkSrc = ref<string | null>(null)
const lightSrc = ref<string | null>(null)

const videoRefLight = ref<HTMLVideoElement | null>(null)
const videoRefDark = ref<HTMLVideoElement | null>(null)

const img = useImage()
/* const videoPlaying = ref(false) */
/* 
watch(theme, () => {
  console.log('theme changed', theme.value)
  if (videoPlaying.value) {
    pauseVideo()
  }

  if (playPromise.value) {
    playPromise.value.then(() => {
      if (videoRef.value) {
        videoRef.value.currentTime = 0
       
        playVideo()
      }
    })
  } else {
    playVideo()
  }
}) */

/* const posterSrc = computed(() => {
  return theme.value === 'light' ? props.posterLight : props.posterDark
})
 */
// play video based on theme
/* function playVideo() {
  console.log('play video')
  if (theme.value === 'light') {
    if (videoRefLight.value) {
      console.log('playing light video')
      darkSrc.value = props.dark
      videoRefLight.value.load()

      playPromise.value = videoRefLight.value.play()
    }
  } else {
    if (videoRefDark.value) {
      console.log('playing dark video')
      lightSrc.value = props.light
      videoRefDark.value.load()
      playPromise.value = videoRefDark.value.play()
    }
  }
  videoPlaying.value = true
} */

const videoPlayed = ref(false)
function playVideo() {
  if ($isSafari) {
    return
  }

  if (!darkSrc.value || !lightSrc.value) {
    darkSrc.value = props.dark
    lightSrc.value = props.light

    videoRefLight.value?.load()
    videoRefDark.value?.load()
  } else {
    videoRefDark.value?.play()
    videoRefLight.value?.play()
  }

  videoPlayed.value = true

  /*   videoRefLight.value?.play()
  videoRefDark.value?.play() */
}

function pauseVideo() {
  if (playPromise.value) {
    playPromise.value.then(() => {
      if (theme.value === 'light') {
        videoRefLight.value?.pause()
      } else {
        videoRefDark.value?.pause()
      }
    })
  }
}

function resetVideo() {
  if (!videoRefLight.value || !videoRefDark.value) return

  videoRefLight.value.currentTime = 0
  videoRefLight.value.pause()
  videoRefDark.value.currentTime = 0
  videoRefDark.value.pause()
  videoPlayed.value = false

  /*   if (theme.value === 'light') {
    if (videoRefLight.value) {
      videoRefLight.value.currentTime = 0
      videoRefLight.value.pause()
    }
  } else {
    if (videoRefDark.value) {
      videoRefDark.value.currentTime = 0
      videoRefDark.value.pause()
    }
  } */
  /*   videoPlaying.value = false */
}

defineExpose({
  playVideo,
  resetVideo,
  pauseVideo,
  videoPlayed,
})
</script>
