<template>
  <div class="w-full py-10 lg:py-32">
    <UContainer class="flex flex-col lg:items-center">
      <h2
        class="t-heading-3 lg:t-heading-2 mb-3 font-semibold text-text-1 lg:mb-8"
      >
        {{ title }}
      </h2>
      <span class="t-body lg:t-subtitle mb-10 text-text-2 lg:mb-16">
        {{ subtitle }}
      </span>
      <!-- DESKTOP LAYOUT WITH ANIMATIONS -->
      <section
        id="animation-wrapper"
        class="relative hidden w-full pb-[30%] md:block"
      >
        <div
          id="animation-content"
          class="sticky top-1/3 grid w-full"
        >
          <div class="relative">
            <article
              v-for="(item, key) in items"
              :id="item.id"
              :key="key"
              class="absolute grid grid-cols-2 gap-16"
              :class="key === 0 ? 'opacity-100' : 'opacity-0'"
            >
              <ThemeVideo
                ref="videoRefs"
                :class="
                  reverse
                    ? 'col-span-1 col-start-2 row-start-1'
                    : 'col-span-1 col-start-1 row-start-1'
                "
                :light="item.videoLight"
                :dark="item.videoDark"
                :poster-dark="item.posterDark"
                :poster-light="item.posterLight"
              />

              <div
                :class="
                  reverse
                    ? 'col-span-1 col-start-1 row-start-1'
                    : 'col-span-1 col-start-2 row-start-1'
                "
                class="text flex flex-col"
              >
                <span
                  class="t-medium lg:t-subtitle text-gradient mb-2 uppercase"
                >
                  {{ item.title }}
                </span>
                <h3
                  class="t-heading-4 lg:t-heading-3 mb-3 font-semibold text-text-1 lg:mb-6"
                >
                  {{ item.subtitle }}
                </h3>
                <p class="t-body lg:t-subtitle text-text-2">
                  {{ item.description }}
                </p>
              </div>
            </article>
          </div>
        </div>

        <div
          v-for="item in items"
          :id="`${item.id}-trigger`"
          :key="item.id"
          :style="{ 'min-height': 'clamp(1200px, 100vh, 2000px)' }"
          class="min-h-screen"
        ></div>
      </section>
      <section class="flex flex-col gap-10 md:hidden">
        <article
          v-for="(item, key) in items"
          :id="item.id"
          :key="key"
          class="flex flex-col gap-3"
        >
          <ThemeImage
            v-if="item.posterLight && item.posterDark"
            class="lg:aspect-[5/4]"
            :loading="'lazy'"
            :alt="item.title"
            :light="item.posterLight"
            :dark="item.posterDark"
          />

          <!-- my-auto -->
          <div class="flex flex-col">
            <span class="t-medium lg:t-subtitle text-gradient mb-2 uppercase">
              {{ item.title }}
            </span>
            <h3
              class="t-heading-4 lg:t-heading-3 mb-3 font-semibold text-text-1 lg:mb-6"
            >
              {{ item.subtitle }}
            </h3>
            <p class="t-body lg:t-subtitle text-text-2">
              {{ item.description }}
            </p>
          </div>
        </article>
      </section>
    </UContainer>
  </div>
</template>
<script setup lang="ts">
const { $gsap: gsap, $ScrollTrigger: ScrollTrigger } = useNuxtApp()

const props = defineProps<{
  items: {
    id: string
    title: string
    subtitle: string
    description: string
    videoDark: string
    videoLight: string
    posterDark?: string
    posterLight?: string
  }[]
  title: string
  subtitle: string
  reverse?: boolean
}>()

const activeItem = ref(props.items[0])
const displayedItems = ref<any[]>([])
const videoPlayedForItem = ref<string | null>(null)
const videoRefs = ref<any[]>([])

const scrollTriggers = ref<ScrollTrigger[]>([])
const mainScrollTrigger = ref<ScrollTrigger | null>(null)
onMounted(() => {
  const mm = gsap.matchMedia()
  mm.add('(min-width: 768px)', () => {
    props.items.forEach((item, index) => {
      const trigger = ScrollTrigger.create({
        trigger: `#${item.id}-trigger`,
        start: 'top 30%',
        end: 'bottom 60%',
        fastScrollEnd: true,
        onEnterBack: (self) => {
          if (Math.abs(self.getVelocity()) > 5000) {
            return
          }
          triggerOnEnterAnimation(item, index, -1)
        },
        onEnter: (self) => {
          if (Math.abs(self.getVelocity()) > 5000) {
            return
          }

          triggerOnEnterAnimation(item, index)
        },
      })
      scrollTriggers.value.push(trigger)
    })

    return () => {
      scrollTriggers.value.forEach((trigger) => {
        trigger.kill()
      })
      mainScrollTrigger.value?.kill()
    }
  })
})

onUnmounted(() => {
  scrollTriggers.value.forEach((trigger) => {
    trigger.kill()
  })
  mainScrollTrigger.value?.kill()
})

function triggerOnEnterAnimation(
  item: any,
  index: number,
  direction: number = 1
) {
  const resetVideosForItem: any[] = []
  videoRefs.value.forEach((video, videoindex) => {
    if (videoindex !== index && video.videoPlayed) {
      resetVideosForItem.push(video)
    } else {
      video.playVideo()
      videoPlayedForItem.value = item.id
    }
  })

  // hide active item only if it's not the same as the new active item
  if (activeItem.value.id === item.id) {
    return
  }

  // hide active item
  gsap.to(`#${activeItem.value.id}`, {
    duration: 0.3,
    opacity: 0,
    onComplete: () => {
      // resetting videos after the animation is done to avoid flickering
      resetVideosForItem.forEach((video) => {
        video.resetVideo()
      })
    },
  })

  // set new active item
  activeItem.value = item

  gsap.set(`#${item.id} .text`, { yPercent: 25 * direction })

  // show new active item
  gsap.to(`#${item.id}`, {
    duration: 0.3,
    opacity: 1,
    delay: 0.15,
  })
  gsap.to(`#${item.id} .text`, {
    yPercent: 0,
    duration: 0.3,
    delay: 0.15,
  })
  displayedItems.value.push(item.id)
  const displayedItemsButCurrent = displayedItems.value.filter(
    (i) => i !== item.id
  )
  if (displayedItemsButCurrent.length > 1) {
    displayedItemsButCurrent.forEach((item) => {
      gsap.to(`#${item.id}`, {
        opacity: 0,
      })
    })
    displayedItems.value = []
  }
}
</script>
